<template>
  <div class="acknowledgeList">
    <template>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(acknowledges)"
        :columns="kgm_responsiveColumns()"
        :filterable="false"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(acknowledges)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="dateTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <span>{{ unix_dateTime_dateTime(kgm_getNestedValue(props.field, props.dataItem)) }}</span>
          </td>
        </template>
        <template
          slot="actionTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            {{ actionText(props.dataItem.action) }}
          </td>
        </template>
        <template
          slot="priorityTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <template v-if="props.dataItem.oldSeverity != 0 && props.dataItem.newSeverity != 0">
              {{ triggerPriorityMixin_priorityText(props.dataItem.oldSeverity) }} -> {{ triggerPriorityMixin_priorityText(props.dataItem.newSeverity) }}
            </template>
          </td>
        </template>
      </Grid>
    </template>
  </div>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js'
import { triggerPriorityMixin } from '@/mixins/triggerPriorityMixin.js';

export default {
  name: "AcknowledgeList",
  mixins: [
    dateTimeMixin,
    kendoGridMixin,
    triggerPriorityMixin
  ],
  props: {
    acknowledges: {
      type: Array,
      required: true
    },
  },
  data () {
    return {
      kgm_take: 3,
      kgm_columns: [
        {
          field: 'clock',
          filterable: false,
          title: 'Changed',
          cell: 'dateTemplate',
        },
        {
          field: 'nessage',
          filterable: false,
          title: this.$t('message')
        },
        {
          field: 'action',
          filterable: false,
          title: 'Action',
          cell: 'actionTemplate',
        },
        {
          filterable: false,
          title: 'Priority Change',
          cell: 'priorityTemplate',
        }
      ]
    }
  },
  methods: {
    actionText (val) {
      var action = Number.parseInt(val);
      var actions = [];
      if((action - 16) >= 0) {
        actions.push("Unacknowledge");
        action = action - 16;
      }
      if((action - 8) >= 0) {
        actions.push("Change Severity");
        action = action - 8;
      }
      if((action - 4) >= 0) {
        action = action - 4;
      }
      if((action - 2) >= 0) {
        actions.push("Acknowledge");
        action = action - 2;
      }
      if((action - 1) >= 0) {
        actions.push("Close");
        action = action - 1;
      }
      return actions.reverse().join(', ');
    }
  }
}
</script>